import BuyMeACoffeeButton from "../Donation/BuyMeACoffeeButton"
import "./Navbar.css"
import Resume from "./Jacky Dam Resume.pdf"
import { NavHashLink as NavLink } from 'react-router-hash-link';


import {
    FaHome
} from 'react-icons/fa';

function Navbar() {
    return <nav className="nav">
        {(
            <NavLink
                to='/'>
                <FaHome
                    className='landing--social'
                    style={{ color: "white" }}
                    aria-label='Github'
                />
            </NavLink>
        )}
        <ul>
            <li class="navbar">
                <NavLink
                    to='/About'
                    smooth={true}
                    spy='true'
                    duration={2000}
                >
                    About Me
                </NavLink>
            </li>
            <li class="navbar">
                <NavLink
                    to='#/Business'
                    smooth={true}
                    spy='true'
                    duration={2000}
                >
                    My Business Proposals
                </NavLink>
            </li>
            <li class="navbar">
                <a href={Resume} target="_blank" rel="noopener noreferrer">Resume</a>
            </li>
            <li class="navbar">
                <NavLink
                    to='#/Projects'
                    smooth={true}
                    spy='true'
                    duration={2000}
                >
                    Projects
                </NavLink>
            </li>
            <li class="navbar">
                <NavLink
                    to='#/Shop'
                    smooth={true}
                    spy='true'
                    duration={2000}
                >
                    Shop
                </NavLink>
            </li>
            <li class="navbar">
                <NavLink
                    to='#/Misc'
                    smooth={true}
                    spy='true'
                    duration={2000}
                >
                    Misc.
                </NavLink>
            </li>
            <BuyMeACoffeeButton />
        </ul>

    </nav>
}

export default Navbar
import "./Education.css"
import education from "./education.png"


function Education() {
    return (
        <div className="main--container-education">
            <img
                src={education}
                alt=''
                className='education--img'
                style={{
                    borderColor: "white",
                }}
            />
            <div className="about--container-education">
                <h1>Education</h1>

                <div className="about--education">
                    <li className="education">
                        <div className="education-header">
                            <h5>University of California, San Diego
                                <h6>Bachelor of Science in Applied Mathematics & Data Science
                                </h6>
                            </h5>
                            <h7>Sept 2018 - Jun 2022</h7>
                        </div>
                        <h3>Relevant Coursework:</h3>

                        <ul>

                            <li className="education-list"> Data Structures, Machine Learning, Deep Learning, Exploratory Data Analysis, Data Mining, Data Science, Applied Linear Algebra, Stochastic Processes, Optimization, Statistics, Mathematics of Finance, Actuarial Mathematics
                            </li>
                        </ul>
                    </li>

                    <li className="education">
                        <div className="education-header">
                            <h5>Coursera
                                <h6>Google Data Analytics Professional Certificate
                                </h6>
                            </h5>
                            <h7>Oct 2021 ‑ Jul 2022</h7>
                        </div>
                        <h3>Relevant Skills:</h3>

                        <ul>

                            <li className="education-list"> Data Cleaning, Data Processing, Data Collection, Data Visualization, Data‑driven Decision Making, Exploratory Data Analysis
                            </li>
                        </ul>
                    </li>
                </div>
            </div>
        </div>
    );
}

export default Education
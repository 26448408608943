import React from 'react'
import { Navbar, Landing } from '../../components'
import { Helmet } from 'react-helmet'
import ParticleBackground from '../../ParticleBackground'

function Main() {
    return (
        <div>
            <Helmet>
                <title>Jacky Dam - Home</title>
            </Helmet>
            <Landing />
            <ParticleBackground />
            <Navbar />

        </div >
    )
}

export default Main
import "./Coursework.css"

function Coursework() {
    return (
        <div className="main--container-coursework">
            <div className="about--container-coursework">
                <h1>Coursework</h1>
                <li>CSE 151A: Machine Learning</li>
                <li>CSE 151B: Deep Learning</li>
                <li>CSE 158: Recommender Systems</li>
                <br></br>
                <li>Math 194: Mathematics of Finance</li>
            </div>
        </div>
    );
}

export default Coursework
import React from "react";
import "./AboutMe.css"
import Navbar from "../Navbar/Navbar"
import about from "./about.png"
import ParticleBackground from '../../ParticleBackground'


import {
    FaGithub,
    FaLinkedinIn
} from 'react-icons/fa';

function AboutMe() {
    return (
        <section className="about-main--container">
            <Navbar />
            <img
                src={about}
                alt=''
                className='about--img'
                style={{
                    borderColor: "white",
                }}
            />
            {/* <ParticleBackground /> */}

            <div className="about-container">

                <div className="about--container-intro">
                    <div className="about--intro">
                        <h1>About Me</h1>
                        <h6>Hi, thanks for taking the opportunity to learn more about me!</h6>
                        <p>My name is Jacky and I'm an aspiring Data Scientist with a Bachelor of Science in Applied Mathematics from UC San Diego. Despite having a degree in Mathematics, I consider myself a tech-savy engineer with a unique technical background which allows me to work on projects which involves: Web Development, Machine Learning, Deep Learning, Data Analytics and Data Science. I'm currently based in San Diego, CA and seeking a job position which allows me to work with other smart, talented people to leverage data and solve difficult analytical problems.
                        </p>
                        <p>At the moment, I'm working part-time as a teaching assistant at an Elementary School where I primarily work with students from a disadvantaged background in order to improve reading literacy and build foundational math skills. I personally enjoy my teaching experience because I know my students greatly benefit from having a young person like me to simplify the learning experience.</p>
                        <p>In my free time, I enjoy developing Asian-fusion cooking recipes, binge-watching reality tv shows on Netflix, volunteering at a cat animal shelter and listening to good R&B music on Spotify. I aspire to some day open up a laid-back speakeasy inspired restaurant, start an origami themed boba stop and develop an AI company to create solutions for optimizing logistical business-related problems.</p>
                    </div>
                </div>


                <div className="about--content">
                    {(
                        <a
                            href={"https://www.linkedin.com/in/jackydam/"}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FaLinkedinIn
                                className='about--social'
                                style={{ color: "white" }}
                                aria-label='LinkedIn'
                            />
                        </a>
                    )}
                    {(
                        <a
                            href={"https://github.com/jacky3331"}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FaGithub
                                className='about--social'
                                style={{ color: "white" }}
                                aria-label='Github'
                            />
                        </a>
                    )}
                </div>
            </div>
        </section>

    );
}

export default AboutMe
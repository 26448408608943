import React from 'react'
import { AboutMe, Education, Skills, Experience, Coursework } from '../../components'
import { Helmet } from 'react-helmet'
import ParticleBackground from '../../ParticleBackground'

function About() {
    return (
        <div>
            <Helmet>
                <title>Jacky Dam - About Me</title>
            </Helmet>
            <AboutMe />
            <Skills />

            <Experience />
            <Education />
            {/* <Coursework /> */}
            {/* <ParticleBackground /> */}



        </div >
    )
}

export default About
import "./Landing.css"
import { NavHashLink as NavLink } from 'react-router-hash-link';
import TypeAnimation from 'react-type-animation';

import {
    FaGithub,
    FaLinkedinIn
} from 'react-icons/fa';

const TypeComponent = () => {
    return (
        <TypeAnimation
            cursor={true}
            sequence={[3000, "Data Scientist", 4500, "Web Developer", 4500, "Data Analyst", 4500, "Product Manager", 4500, "Machine Learning Engineer", 3000]}
            wrapper="h6"
            repeat={Infinity}
        />
    );
};

function Landing() {
    return (
        <section className="main--container">
            <div className="landing--container">
                <div className="landing--container-bio">
                    <div className="landing--bio">
                        <h6><TypeComponent /></h6>
                        <h1>Jacky Dam</h1>
                        <p>I am a recent graduate from UC San Diego and recieved a Bachelor of Science in Applied Mathematics with a specialization in Machine Learning and Deep Learning algorithms. Currently seeking a role as a Data Scientist and looking towards making my contribution to the team and creating solutions for difficult problems with my technical background. Available for inquiries, job openings or business opportunities.
                        </p>
                    </div>
                    <div className='landing--bio-buttonContainer'>
                        <NavLink
                            to='/#contacts'
                            smooth={true}
                            spy='true'
                            duration={2000}
                            className="bio-btn1"
                        >
                            Contact
                        </NavLink>
                        <NavLink
                            to='/About'
                            smooth={true}
                            spy='true'
                            duration={2000}
                            className="bio-btn2"
                        >
                            Learn More
                        </NavLink>

                    </div>
                </div>

                <div className="landing--content">
                    {(
                        <a
                            href={"https://www.linkedin.com/in/jackydam/"}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FaLinkedinIn
                                className='landing--social'
                                style={{ color: "white" }}
                                aria-label='LinkedIn'
                            />
                        </a>
                    )}
                    {(
                        <a
                            href={"https://github.com/jacky3331"}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FaGithub
                                className='landing--social'
                                style={{ color: "white" }}
                                aria-label='Github'
                            />
                        </a>
                    )}
                </div>

            </div>

        </section >

    );
}

export default Landing
import "./Experience.css"
import experience from "./experience.png"


function Experience() {
    return (
        <div className="main--container-experience">
            <img
                src={experience}
                alt=''
                className='experience--img'
                style={{
                    borderColor: "white",
                }}
            />
            <div className="about--container-experience">
                <h1>Experience</h1>
                {/* Experience Card */}
                <div className="about--experience">
                    <li className="experience">
                        <div className="experience-header">
                            <h5>Kitchen Staff
                                <h6>Wushiland Boba</h6>
                            </h5>
                            <h7>Jul 2021 - Nov 2021</h7>
                        </div>
                        <ul>
                            <li className="experience-list">Opened store-front daily at 8am in the morning with a consistent schedule.</li>
                            <li className="experience-list">Prepared high-quality teas and bobas to be served throughout the day with coordination among front baristas.</li>
                            <li className="experience-list">Worked as a front barista, being flexible with work schedule and maintaining exceptional customer service.</li>

                        </ul>
                    </li>

                    {/* <li className="experience">
                        <div className="experience-header">
                            <h5>Animal Shelter Volunteer
                                <h6>The Cat Lounge Rescue and Adoption Center</h6>
                            </h5>
                            <h7>Nov 2021 - Present</h7>
                        </div>
                        <ul>
                            <li className="experience-list">Volunteering bi-weekly as a cat caretaker at a non-profit local animal shelter.</li>
                            <li className="experience-list">Maintaining a consistent work schedule and upkeeping with cleaning and caretaking assignments.</li>
                        </ul>
                    </li> */}

                    <li className="experience">
                        <div className="experience-header">
                            <h5>Hackathon Mentor
                                <h6>DataHacks @ UCSD</h6>
                            </h5>
                            <h7>Apr 2022</h7>
                        </div>
                        <ul>
                            <li className="experience-list">Mentored multiple teams in a 24‑hour long hackathon where college students learn how to apply data science tools on real life datasets.</li>
                            <li className="experience-list">Applied my experience in statistical data analysis and machine learning algorithms to help others develop models and visualizations.</li>
                            <li className="experience-list">Worked with students to provide guidance on beginner, intermediate, advanced data science projects and resolved bugs via Zoom meetings.</li>
                        </ul>
                    </li>

                    <li className="experience">
                        <div className="experience-header">
                            <h5>Math Tutor
                                <h6>EDS 136: Academic Tutoring of Secondary School Students</h6>
                            </h5>
                            <h7>Mar 2022 - Jun 2022</h7>
                        </div>
                        <ul>
                            <li className="experience-list">Engaged with high school students in a local underrepresented community, teaching students effective studying and test taking strategies.</li>
                            <li className="experience-list">Practiced clear communication to resolve misconceptions in course material and understanding how to tackle challenging problems.</li>
                            <li className="experience-list">Created an interactive website where students can find study groups, ask questions about homework problems and find learning resources.</li>
                        </ul>
                    </li>

                    <li className="experience">
                        <div className="experience-header">
                            <h5>Elementary School Teacher Assistant
                                <h6>UC San Diego</h6>
                            </h5>
                            <h7>Jun 2022 - Present</h7>
                        </div>
                        <ul>
                            <li className="experience-list">Engaged with students in a local underrepresented community, improving reading literacy, reading and building foundational math skills.</li>
                            <li className="experience-list">Worked one‑on‑one with students and led hands‑on group activities to teach efficient workflow for students transitioning to middle school.</li>
                        </ul>
                    </li>
                </div>
            </div>
        </div>
    );
}

export default Experience
import Particles from "react-tsparticles"
import { loadSlim } from "tsparticles-slim"
import { useCallback, useMemo } from "react"

const ParticleBackground = () => {
    const options = useMemo(() => {
        return {
            interactivity: {
                events: {
                    // onClick: {
                    //     enable: true,
                    //     mode: "push"
                    // },
                    onHover: {
                        enable: true,
                        mode: "repulse"
                    }
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 100,
                    }
                }
            },
            particles: {
                links: {
                    enable: false,
                },
                move: {
                    enable: true,
                    speed: { min: 1, max: 2 }
                },
                opacity: {
                    value: { min: 0.3, max: 0.7 }
                },
                size: {
                    value: { min: 1, max: 2 }
                }
            }
        };
    }, []);

    const particlesInit = useCallback((engine) => {
        loadSlim(engine);
    }, []);

    return <Particles init={particlesInit} options={options} />;
};

export default ParticleBackground
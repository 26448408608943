import "./Skills.css"
import work from "./work.png"

function Skills() {
    return (
        <div className="skills-main--container">
            <div className="line-styling">
                <div className="style-circle" style={{ backgroundColor: "white" }}></div>
                <div className="style-circle" style={{ backgroundColor: "white" }}></div>
                <div className="style-line" style={{ backgroundColor: "white" }}></div>
            </div>
            <img
                src={work}
                alt=''
                className='skills--img'
                style={{
                    borderColor: "white",
                }}
            />
            <div className="about--container-skills">
                <div className="about-skills">
                    <h1>What I Offer to the Team</h1>
                    <h6>Tech-savy engineer with a passion to create solutions with abstract data</h6>

                    <div className="about-careers">
                        <ul>
                            <li className="careers">
                                <h5>Data Scientist
                                    <h6>Skills: Machine Learning, Data Visualization, Data Processing, Data Mining, Exploratory Data Analysis, Statistical Modeling</h6>
                                </h5>

                                <ul>
                                    <li className="data-scientist-list">Building infrastructure to create data pipelines and collect large chunks of user information.</li>
                                    <li className="data-scientist-list">Working with large datasets and solve difficult analysis problems, applying advanced analytical methods.</li>
                                    <li className="data-scientist-list">Making business recommendations with powerpoint presentations of findings through data visualizations and analysis.</li>
                                    <li className="data-scientist-list">Collaborating with my team to make improvements on current methodologies through optimization and experimentation.</li>

                                </ul>
                            </li>
                            <li className="careers">
                                <h5>Data Analyst
                                    <h6>Skills: Data Visualization, Data Processing, Exploratory Data Analysis, Statistical Modeling</h6>
                                </h5>
                                <ul>
                                    <li className="data-scientist-list">Processing large chunks of raw data with Python libraries and SQL.</li>
                                    <li className="data-scientist-list">Building predictive models with Machine Learning algorithms.</li>
                                    <li className="data-scientist-list">Analyzing the trends and pattern of data with visualizations, aggregations and statistical methods.</li>
                                </ul>
                            </li>
                            <li className="careers">
                                <h5>Machine Learning Engineer
                                    <h6>Skills: Machine Learning, Deep Learning, Natural Language Processing, Recommender Systems</h6>
                                </h5>

                                <ul>
                                    <li className="data-scientist-list">Creating models to make exceptional recommendations for customers and predicting trends/outcomes in the future with abstract data.</li>
                                    <li className="data-scientist-list">Collaborating with others to make improvements on current model preformances through experimentations and feature engineering.</li>
                                    <li className="data-scientist-list">Building Neural Networks appliactions to model complex patterns from abstract and ambiguous data.</li>
                                </ul>
                            </li>
                            <li className="careers">
                                <h5>Web Developer
                                    <h6>Skills: Python, React, CSS, HTML, JavaScript, Adobe Photoshop, Cloudflare, Front-end Engineering, Back-end Engineering</h6>

                                </h5>
                                <ul>
                                    <li className="data-scientist-list">Building responsive web applications with React, JavaScript and CSS.</li>
                                    <li className="data-scientist-list">Designing the architecture of web pages in consideration of user-friendly design, functionality and optimization.</li>
                                    <li className="data-scientist-list">Working with web applications to create data infrastructures and deploying websites with web-hosting services.</li>
                                </ul>
                            </li>
                            {/* <li className="careers">
                                <h5>Product Manager
                                    <h6>Skills: Adaptability, Conflict Resolution, Organization, Powerpoint, Proactive, Strategic Planning</h6>
                                </h5>
                                <ul>
                                    <li className="data-scientist-list">Working in Progess.</li>
                                    <li className="data-scientist-list">Working in Progess.</li>
                                    <li className="data-scientist-list">Working in Progess.</li>
                                </ul>
                            </li>
                            <li className="careers">
                                <h5>Teacher/Mentor
                                    <h6>Skills: Communication, Conflict Resolution, Organization, Team-building, Time Management</h6>

                                </h5>
                                <ul>
                                    <li className="data-scientist-list">Working in Progess.</li>
                                    <li className="data-scientist-list">Working in Progess.</li>
                                    <li className="data-scientist-list">Working in Progess.</li>
                                </ul>
                            </li> */}
                        </ul>

                    </div>

                </div>

            </div>
        </div>
    );
}

export default Skills